import { collection, onSnapshot } from "firebase/firestore";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { db } from "../../Firebase/Firebase";

const UserTable = ({ item }) => {
  const navigate = useNavigate();

  const [userdetails, setUserdetails] = useState();

  useEffect(() => {
    const q = collection(db, "usersLesson", item?.id, "MemberShip");

    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setUserdetails(Blog);
      });
    });

    return () => unsub();
  }, [userdetails]);
  console.log(userdetails);

  return (
    <tbody>
      <tr key={item.id}>
        <th scope="row">
          <div className="user-profile-n">
            <span>
              <FaUserCircle size={30} />
            </span>
            {item.name}
          </div>
        </th>
        <td data-title="Email">{item.email}</td>
        {userdetails?.map((item) => (
          <>
          {item.unlock === true ? (
                 <td data-title="Payment Type">
        OTP
              </td>
              ) : (
                <td data-title="Payment Type">
          EMI
            </td>
                )}
            </>
          ))}
        <td data-title="Payment Type">
          {userdetails?.map((item) => (
            <h1 style={{ fontSize: " 17px", fontWeight: "100" }}>
              {item.Subscription}
            </h1>
          ))}
          {/* <UserComponent1 id={item.id} /> */}
        </td>

        <td
          className="pay-status"
          data-title="Payment Status"
          data-type="currency"
        >
          {userdetails?.map((item) => (
            <>
              {item.Active === true ? (
                <MdDone size={30} />
              ) : (
                <RxCross1 size={30} />
              )}
            </>
          ))}
        </td>
        <td data-title="Enroll Date">
        {
  item?.createdAt
    ? moment(item.createdAt.toDate ? item.createdAt.toDate() : item.createdAt).format("MMMM Do YYYY")
    : null
}

        </td>
        <td data-title="Action" data-type="currency">
          <button
            onClick={() =>
              navigate(`/Users/${item.id}`, {
                state: { user: item },
              })
            }
            className="view-btn-2"
          >
            View
          </button>
        </td>
      </tr>
    </tbody>
  );
};

export default UserTable;
